import { Fragment, useState, useEffect } from "react";
import Link from "next/link";
import { Dialog, Transition } from "@headlessui/react";
import {
  ListModal as ReservoirListModal,
  ListStep,
} from "@reservoir0x/reservoir-kit-ui";

import { Close } from "client/components/icons/close";
import { Check } from "client/components/icons/check";
import { Eth } from "client/components/icons/eth";
import { numberWithCommas } from "client/lib/helpers";
import { readAsset } from "client/lib/api";
import { getCollectionIdByToken } from "shared/reservoir";
import { Asset } from "shared/types/asset";
import AssetSummary from "client/components/common/modal/AssetSummary";
import CrossPostOpenSeaInfo from "client/components/common/modal/CrossPostOpenSeaInfo";
import ApprovePending from "client/components/common/modal/ApprovePending";
import SignPending from "@/client/components/common/modal/SignPending";
import {
  DateDropdown,
  DateOption,
} from "client/components/common/modal/DateDropdown";
import { getMarketFromName, Market } from "shared/marketplaces";
import ErrorComponent from "client/components/common/modal/ErrorComponent";
import XShareButton from "../XShareButton";
import { getAssetUrl } from "@/client/lib/links";
import { getMarketplaceListingFeePercent } from "@/shared/marketplaceFees";
import { getBasePath } from "@/shared/config";

type Props = {
  tokenId?: string;
  contractAddress?: string;
  price?: number;
  relistMode?: boolean;
  open?: boolean;
  setOpen: (open: boolean) => void;
  onSuccess?: () => void;
};

/**
 * Modal to list an item for sale via reservoir
 *
 * Uses ReservoirListModal.Custom to render a custom UI with data fetched from Reservoir API
 *
 * Heavily influenced by how Reservoir use ListModal & ListModalRenderer
 * ListModal - https://github.com/reservoirprotocol/reservoir-kit/blob/c43e92a08da5fef0fc85a1e12afa48f58d17a0a1/packages/ui/src/modal/list/ListModal.tsx#L169
 * ListModalRenderer - https://github.com/reservoirprotocol/reservoir-kit/blob/c43e92a08da5fef0fc85a1e12afa48f58d17a0a1/packages/ui/src/modal/list/ListModalRenderer.tsx
 */
export const ListModal = ({
  tokenId,
  contractAddress,
  open = false,
  price,
  relistMode = false,
  setOpen,
  onSuccess,
}: Props) => {
  // tokenId and contractAddress are required
  if (!tokenId || !contractAddress) return null;

  const handeOnClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog as="div" className="relative" onClose={handeOnClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black dark:bg-opacity-75 bg-opacity-25 z-40" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto z-50">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-xl transform overflow-visible rounded-3xl text-left align-middle shadow-xl transition-all dark:bg-neutral-900 dark:text-white bg-neutral-100">
                  <ListComponent
                    tokenId={tokenId}
                    contractAddress={contractAddress}
                    price={price}
                    relistMode={relistMode}
                    onClose={handeOnClose}
                    onSuccess={onSuccess}
                  />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export enum Stage {
  SetDetails = "SET_DETAILS",
  Approve = "APPROVE",
  Sign = "SIGN",
  Complete = "COMPLETE",
}

export const ListComponent = ({
  tokenId,
  contractAddress,
  price: presetPrice,
  relistMode = false,
  onClose,
  onSuccess,
}: {
  tokenId: string;
  contractAddress: string;
  price?: number;
  relistMode?: boolean;
  onClose: any;
  onSuccess?: () => void;
}) => {
  const [asset, setAsset] = useState<Asset | null>();
  const [stage, setStage] = useState<Stage>(Stage.SetDetails);
  const [loadedInitialPrice, setLoadedInitialPrice] = useState<boolean>(false);

  useEffect(() => {
    // fetch asset data for the UI (instead of reservoir asset data)
    refreshAsset();
  }, [tokenId, contractAddress]);

  const refreshAsset = async () => {
    if (!contractAddress || !tokenId) return;
    try {
      const result = await readAsset(contractAddress, tokenId);
      setAsset(result);
    } catch (err) {
      console.error(err);
    }
  };

  const handleCloseClicked = () => {
    if (onClose) onClose();
  };

  const handleOnComplete = () => {
    if (onSuccess) onSuccess();
  };

  return (
    <>
      <div className="p-6 py-4">
        <div className="flex flex-shrink-0 items-center justify-between">
          <Dialog.Title
            as="h3"
            className="text-lg font-medium leading-6 text-gray-900 dark:text-white"
          >
            {getStageTitle(stage, relistMode)}
          </Dialog.Title>
          <button
            onClick={handleCloseClicked}
            className="bg-neutral-200 dark:bg-neutral-800 dark:hover:bg-neutral-700 rounded-full w-8 h-8 flex flex-col justify-center items-center"
          >
            <Close />
          </button>
        </div>
      </div>
      <ReservoirListModal.Custom
        open={true}
        tokenId={tokenId}
        oracleEnabled={false}
        collectionId={getCollectionIdByToken(
          contractAddress as string,
          tokenId as string
        )}
      >
        {({
          loading,
          token,
          quantityAvailable,
          collection,
          usdPrice,
          listStep,
          marketplace,
          exchange,
          expirationOption,
          expirationOptions,
          listingData,
          transactionError,
          stepData,
          price,
          currencies,
          currency,
          quantity,
          setPrice,
          listToken,
          setCurrency,
          setExpirationOption,
          setQuantity,
          royaltyBps,
        }) => {
          // pick out the top trait floor if available
          const topTraitFloor = Math.max(
            ...(token?.token?.attributes?.map((attr) =>
              Number(attr?.floorAskPrice?.amount?.native || 0)
            ) || []),
            0
          );

          // filter out the "custom" option from dates since we don't have an input for it
          const filteredExpirationOptions = expirationOptions?.filter(
            (option: DateOption) => option?.value !== "custom"
          );

          // Setup the step/stage specific data
          useEffect(() => {
            // set the current stage we are on
            switch (listStep) {
              case ListStep.SetPrice:
                setStage(Stage.SetDetails);
                break;
              case ListStep.Listing:
                if (stepData?.currentStep?.kind === "transaction") {
                  setStage(Stage.Approve);
                } else if (stepData?.currentStep?.kind === "signature") {
                  setStage(Stage.Sign);
                }
                break;
              case ListStep.Complete:
                setStage(Stage.Complete);
                break;
            }

            // if there is an error jump back to the starting stage
            if (transactionError) {
              setStage(Stage.SetDetails);
            }
          }, [listStep, stepData, transactionError]);

          // if we pass a preset price to the component set it
          useEffect(() => {
            // make sure we only set the preset price once at the start
            if (loadedInitialPrice) return;

            if (presetPrice) {
              setPrice(presetPrice.toString());
              setLoadedInitialPrice(true);
            }
          }, [presetPrice]);

          // when completed fire any on success handlers
          useEffect(() => {
            if (stage === Stage.Complete) handleOnComplete();
          }, [stage]);

          const handleListItemClicked = () => {
            listToken();
          };

          const handePriceChanged = (price: string) => {
            setPrice(price);
          };

          const handleExpirationDateChanged = (
            expirationOption: DateOption
          ) => {
            setExpirationOption(expirationOption);
          };

          return (
            <ListComponentBody
              contractAddress={contractAddress}
              tokenId={tokenId}
              name={asset?.name}
              imageUrl={asset?.imageThumbnailUrl}
              lastPrice={token?.token?.lastSale?.price?.amount?.native}
              collectionFloor={collection?.floorAsk?.price?.amount?.native}
              topTraitFloor={topTraitFloor}
              error={transactionError || null}
              onListItemClicked={handleListItemClicked}
              usdPrice={usdPrice}
              price={price}
              onPriceChanged={handePriceChanged}
              onExpirationDateChanged={handleExpirationDateChanged}
              expirationDateOptions={filteredExpirationOptions}
              selectedExpirationDateOption={expirationOption}
              stage={stage}
              relistMode={relistMode}
              royaltyBps={royaltyBps}
            />
          );
        }}
      </ReservoirListModal.Custom>
    </>
  );
};

export const ListComponentBody = ({
  contractAddress,
  tokenId,
  name,
  artistName,
  lastPrice,
  imageUrl,
  collectionFloor,
  topTraitFloor,
  stage,
  usdPrice,
  price,
  onPriceChanged,
  onListItemClicked,
  onExpirationDateChanged,
  expirationDateOptions,
  selectedExpirationDateOption,
  error,
  relistMode,
  royaltyBps,
}: {
  contractAddress: string;
  tokenId: string;
  name?: string;
  imageUrl?: string;
  artistName?: string;
  lastPrice?: number | undefined;
  usdPrice?: number;
  collectionFloor?: number | undefined;
  topTraitFloor?: number | undefined;
  stage: Stage;
  onListItemClicked: () => void;
  price: string;
  onPriceChanged: (price: string) => void;
  expirationDateOptions: DateOption[];
  selectedExpirationDateOption: DateOption;
  onExpirationDateChanged: (dateOption: DateOption) => void;
  error?: Error | null;
  relistMode?: boolean;
  royaltyBps?: number;
}) => {
  const renderBody = () => {
    switch (stage) {
      case Stage.SetDetails:
        return (
          <SetDetails
            imageUrl={imageUrl}
            name={name}
            artistName={artistName}
            lastPrice={lastPrice}
            collectionFloor={collectionFloor}
            topTraitFloor={topTraitFloor}
            price={price}
            royaltyBps={royaltyBps}
            onPriceChanged={onPriceChanged}
            onListItemClicked={onListItemClicked}
            onExpirationDateChanged={onExpirationDateChanged}
            expirationDateOptions={expirationDateOptions}
            selectedExpirationDateOption={selectedExpirationDateOption}
            usdPrice={usdPrice}
            relistMode={relistMode}
          />
        );
      case Stage.Approve:
        return <ApprovePending market={Market.Sansa} />;
      case Stage.Sign:
        return (
          <SignPending
            market={Market.Sansa}
            marketPrice={price ? Number(price) : undefined}
            marketPriceCurrencySymbol={"ETH"} // TODO: use real currency symbol
            imageUrl={imageUrl}
            name={name}
            artistName={artistName}
          />
        );
      case Stage.Complete:
        return (
          <CompleteStage
            contractAddress={contractAddress}
            tokenId={tokenId}
            imageUrl={imageUrl}
            name={name}
            artistName={artistName}
            lastPrice={lastPrice}
            collectionFloor={collectionFloor}
            listedPrice={price}
          />
        );
    }
  };

  return (
    <>
      {error ? (
        <div className="px-8 pb-6">
          <ErrorComponent error={error?.message} />
        </div>
      ) : null}
      {renderBody()}
    </>
  );
};

const SetDetails = ({
  imageUrl,
  name,
  artistName,
  lastPrice,
  collectionFloor,
  topTraitFloor,
  onListItemClicked,
  usdPrice,
  price,
  royaltyBps,
  onPriceChanged,
  expirationDateOptions,
  selectedExpirationDateOption,
  onExpirationDateChanged,
  relistMode,
}: {
  imageUrl?: string;
  name?: string;
  artistName?: string;
  lastPrice?: number;
  collectionFloor?: number;
  topTraitFloor?: number;
  onListItemClicked: () => void;
  usdPrice: number | undefined;
  price: string;
  royaltyBps?: number;
  onPriceChanged: (price: string) => void;
  expirationDateOptions: DateOption[];
  selectedExpirationDateOption: DateOption;
  onExpirationDateChanged: (expirationOption: DateOption) => void;
  relistMode?: boolean;
}) => {
  const handleListItemClicked = () => {
    if (onListItemClicked) onListItemClicked();
  };

  return (
    <>
      <AssetSummary
        imageUrl={imageUrl}
        name={name}
        artistName={artistName}
        royaltyBps={royaltyBps}
        stats={{
          collectionFloor,
          topTraitFloor,
          lastPrice,
        }}
      />
      <div className="px-8 py-6">
        <div className="flex flex-col gap-6">
          <div className="relative flex items-start">
            <div className="flex items-center justify-between w-full">
              <div className="flex items-center gap-2">
                <img
                  className="h-10 w-10"
                  src={`${getBasePath()}/market-icon.svg`}
                />
                <div className="flex flex-col">
                  <p>Art Blocks</p>
                  <p className="text-xs text-neutral-500">
                    {`${getMarketplaceListingFeePercent()}% Marketplace Fee`}
                  </p>
                </div>
              </div>
              <div className="flex items-center">
                <div className="flex w-32 bg-neutral-100 dark:bg-neutral-800 dark:border-neutral-700 items-center rounded-xl border border-neutral-300 overflow-hidden text-gray-500">
                  <div className="w-10 h-10 flex-shrink-0 flex flex-col items-center dark:border-neutral-700 border-neutral-300 justify-center border-r">
                    <Eth
                      size={18}
                      className="text-neutral-600 dark:text-neutral-200"
                    />
                  </div>
                  <input
                    type="number"
                    name={`price`}
                    id={`price`}
                    className="h-10 bg-neutral-100 dark:bg-neutral-800 text-neutral-900 block w-full px-2 dark:placeholder:text-neutral-300 dark:text-white"
                    placeholder="Price"
                    aria-describedby="price-currency"
                    value={price}
                    onChange={(e) => {
                      onPriceChanged(e.target.value);
                    }}
                  />
                </div>
                <div
                  className={`h-10 ${
                    price ? "ml-2" : `w-0`
                  } overflow-hidden flex flex-col items-end text-right justify-center transition-all`}
                >
                  <p
                    className={`text-neutral-500 dark:text-neutral-400 text-sm`}
                  >
                    $
                    {price && usdPrice
                      ? numberWithCommas(
                          Number((Number(price) * usdPrice).toFixed(2))
                        )
                      : " --"}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="relative flex items-start">
            <CrossPostOpenSeaInfo mode="LIST" />
          </div> */}
        </div>
        <div className="py-6 flex items-center gap-6">
          <div>
            <div className="flex items-center gap-4">
              <DateDropdown
                options={expirationDateOptions}
                onSelectedOptionChanged={onExpirationDateChanged}
                selectedOption={selectedExpirationDateOption}
              />
            </div>
          </div>
          <div className="flex-grow">
            <button
              onClick={handleListItemClicked}
              className="w-full h-14 dark:bg-white dark:text-black dark:hover:bg-neutral-300 hover:bg-neutral-700 text-white bg-black rounded-full font-medium"
            >
              {relistMode ? "Lower Price" : "List Item"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const CompleteStage = ({
  contractAddress,
  tokenId,
  imageUrl,
  name,
  artistName,
  listedPrice,
}: {
  contractAddress: string;
  tokenId: string;
  imageUrl?: string;
  name?: string;
  artistName?: string;
  lastPrice?: number;
  collectionFloor?: number;
  listedPrice?: any;
}) => {
  const assetLink = getAssetUrl(contractAddress, tokenId);
  return (
    <>
      <AssetSummary imageUrl={imageUrl} name={name} artistName={artistName} />
      <div className="px-6 border-t">
        <XShareButton
          socialMsg={
            listedPrice
              ? `I'm selling ${name} on @artblocks_io for ${listedPrice} ETH \n ${assetLink}`
              : `I'm selling ${name} on @artblocks_io \n ${assetLink}`
          }
          location="listing modal"
          title="Share your listing"
        />
      </div>
      <div className="px-6 py-6 flex items-center justify-between">
        <div className="flex dark:text-green-400 text-green-600 items-center gap-3">
          <div className="items-center flex flex-col justify-center rounded-full h-8 w-8 dark:bg-green-900 bg-green-100">
            <Check
              size={32}
              className="bg-green-700 text-white rounded-full mx-auto"
            />
          </div>{" "}
          Asset Listed
        </div>
        <div className="flex flex-col gap-6">
          <div className="flex items-center gap-4">
            <Link
              href={`/asset/${contractAddress}/${tokenId}`}
              className="bg-white dark:bg-neutral-800 dark:text-white text-black px-6 p-3 transition hover:opacity-80 hover:shadow-xl shadow-sm rounded-md"
            >
              View Asset
            </Link>
            <Link
              href={`/listings`}
              className="bg-white dark:bg-neutral-700 dark:text-white text-black px-6 p-3 transition hover:opacity-80 hover:shadow-xl shadow-sm rounded-md"
            >
              Manage Listings
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

const getStageTitle = (stage: Stage, relistMode?: boolean) => {
  switch (stage) {
    case Stage.SetDetails:
      if (relistMode) {
        return "Lower listing";
      }
      return "List Item";
    case Stage.Approve:
      return "Approve Marketplace";
    case Stage.Sign:
      return "Approve Listing";
    case Stage.Complete:
      return "Successfully Listed";
  }
};
