import * as React from "react";

interface Props extends React.SVGAttributes<SVGElement> {
  size?: number;
}

export const X2Y2 = ({ size = 24, ...props }: Props) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 640 640`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 320C0 496.731 143.269 640 320 640C496.731 640 640 496.731 640 320C640 143.269 496.731 0 320 0C143.269 0 0 143.269 0 320Z"
        fill="white"
      />
      <path
        d="M523.265 164.353C484.038 126.023 430.378 102.4 371.2 102.4C251.023 102.4 153.6 199.823 153.6 320C153.6 440.177 251.023 537.6 371.2 537.6C430.377 537.6 484.038 513.977 523.265 475.647C476.474 536.661 402.831 576 320 576C178.615 576 64 461.385 64 320C64 178.615 178.615 64 320 64C402.831 64 476.474 103.339 523.265 164.353Z"
        fill="url(#paint0_linear_504_54)"
      />
      <path
        d="M208.588 444.518C239.97 475.182 282.898 494.08 330.24 494.08C426.382 494.08 504.32 416.142 504.32 320C504.32 223.858 426.382 145.92 330.24 145.92C282.898 145.92 239.97 164.818 208.588 195.482C246.021 146.671 304.935 115.2 371.2 115.2C484.308 115.2 576 206.892 576 320C576 433.108 484.308 524.8 371.2 524.8C304.935 524.8 246.021 493.329 208.588 444.518Z"
        fill="url(#paint1_linear_504_54)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M473.6 320C473.6 404.831 404.831 473.6 320 473.6C235.169 473.6 166.4 404.831 166.4 320C166.4 235.169 235.169 166.4 320 166.4C404.831 166.4 473.6 235.169 473.6 320ZM422.4 320C422.4 376.554 376.554 422.4 320 422.4C263.446 422.4 217.6 376.554 217.6 320C217.6 263.446 263.446 217.6 320 217.6C376.554 217.6 422.4 263.446 422.4 320Z"
        fill="url(#paint2_linear_504_54)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_504_54"
          x1="64"
          y1="311.172"
          x2="576"
          y2="311.172"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00E0FF" />
          <stop offset="1" stopColor="#562EC8" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_504_54"
          x1="63.9998"
          y1="311.172"
          x2="576"
          y2="311.172"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00E0FF" />
          <stop offset="1" stopColor="#562EC8" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_504_54"
          x1="64.0008"
          y1="311.172"
          x2="576"
          y2="311.172"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00E0FF" />
          <stop offset="1" stopColor="#562EC8" />
        </linearGradient>
      </defs>
    </svg>
  );
};
