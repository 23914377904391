import ReactTooltip from "react-tooltip";
import { getMarketDisplayName } from "shared/marketplaces";

import { LooksRare } from "./looksrare";
import { OpenSea } from "./opensea";
import { X2Y2 } from "./x2y2";
import { getBasePath } from "@/shared/config";

interface MarketIcon {
  name: string | null | undefined;
  imageUrl?: string;
  size?: number;
}

const MarketIcon = ({ name: market, size = 24, imageUrl }: MarketIcon) => {
  const lowercaseMarket = market?.toLowerCase();

  const renderMarketIcon = () => {
    switch (lowercaseMarket) {
      case "opensea":
        return <OpenSea size={size} className="flex-shrink-0" />;
      case "looksrare":
        return <LooksRare size={size} className="flex-shrink-0" />;
      case "x2y2":
        return <X2Y2 size={size ? size + 4 : 24} className="flex-shrink-0" />;
      case "art blocks":
        return (
          <img
            style={{ width: size }}
            src={`${getBasePath()}/logos/artblocks.png`}
            className="flex-shrink-0"
          />
        );
      case "sansa":
        return (
          <img
            style={{ width: size }}
            src={`${getBasePath()}/market-icon.svg`}
            className="flex-shrink-0"
          />
        );
      case "sansa.xyz":
        return (
          <img
            style={{ width: size }}
            src={`${getBasePath()}/market-icon.svg`}
            className="flex-shrink-0"
          />
        );
      default:
        if (imageUrl)
          return (
            <img
              style={{ width: size }}
              src={imageUrl}
              className="flex-shrink-0"
            />
          );
        return (
          <div
            style={{ width: size, height: size, lineHeight: "20px" }}
            className={`rounded-full text-white text-center bg-stone-400 block flex-shrink-0`}
          >
            ?
          </div>
        );
    }
  };

  return (
    <>
      <ReactTooltip
        id="market-tooltip"
        backgroundColor="black"
        className="flat-color"
        effect="solid"
        place="left"
      />
      <div
        data-for="market-tooltip"
        data-tip={lowercaseMarket ? getMarketDisplayName(lowercaseMarket) : ""}
        className="flex-shrink-0"
        style={{ width: size, height: size }}
      >
        {renderMarketIcon()}
      </div>
    </>
  );
};

export default MarketIcon;
